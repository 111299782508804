import {AbstractControl, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { AfterViewInit, OnInit, Component } from '@angular/core';
import {AppInjector} from '../util/app.injector';
import {BaseComponent} from './base.component';

@Component({
    template: ''
})
export abstract class BaseFormComponent extends BaseComponent implements OnInit, AfterViewInit {

    protected formBuilder: UntypedFormBuilder = AppInjector.get(UntypedFormBuilder);

    form: UntypedFormGroup;

    constructor() {
        super();
    }

    ngOnInit() {
        this.initForm();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    initForm() {
        this.form = this.formBuilder.group(this.getFormControls(), {});
    }

    hasError(field: AbstractControl) {
        if (field.invalid && (field.dirty || field.touched)) {
            return 'has-error';
        }
    }

    abstract getFormControls();

    abstract onSubmit();
}
